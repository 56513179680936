

// import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Routes from "./routes/routes";
// import { checkAdminStatus } from "./utils/adminStatusCheck";
// import "./App.css";
// import "../src/assets/css/icon.css";
// import "../src/assets/css/font-awesome.min.css";

// function App() {
//   const location = useLocation();

//   useEffect(() => {
//     // console.log(`Current location: ${location.pathname}`);
//     checkAdminStatus();
//   }, [location]);

//   return <Routes />;
// }

// export default App;


import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Routes from "./routes/routes";
import { checkAdminStatus } from "./utils/adminStatusCheck";
import { validateToken } from "../src/utils/handleToken";
import { logout } from "../src/redux/action/LoginAction/LoginAction";
import "./App.css";
import "../src/assets/css/icon.css";
import "../src/assets/css/font-awesome.min.css";
import { toast } from "react-toastify";

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const entireState = useSelector(state => state);
  // console.log("Entire Redux State:", entireState);

  const loginSuccesData = useSelector(state => state.loginData?.loginSuccesData ?? {});
  
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  useEffect(() => {
    const hasAccessToken = !!loginSuccesData?.accessToken;
    setIsLoggedOut(!hasAccessToken);
    // console.log("Is user logged out?", !hasAccessToken);
  }, [loginSuccesData]);

  useEffect(() => {
    const checkTokenAndAdmin = async () => {
      setIsValidatingToken(true);
      try {
        if (!isLoggedOut) {
          const tokenValidationResult = await validateToken();
          
          if (tokenValidationResult === null) {
            // console.log("Token invalid or expired. Logging out.");
            // toast.error("Token invalid or expired. Logging out..");
            dispatch(logout());
            setIsLoggedOut(true);
            localStorage.removeItem('lastValidPath');
            history.replace("/login");
          } else {
            // console.log("Token valid. Checking admin status.");
            checkAdminStatus();
            localStorage.setItem('lastValidPath', location.pathname);
          }
        }
      } catch (error) {
        console.error("Error in token validation:", error);
        dispatch(logout());
        setIsLoggedOut(true);
        localStorage.removeItem('lastValidPath');
        history.replace("/login");
      } finally {
        setIsValidatingToken(false);
      }
    };

    checkTokenAndAdmin();
  }, [location, dispatch, history, isLoggedOut]);

  useEffect(() => {
    if (!isValidatingToken) {
      if (isLoggedOut && location.pathname !== "/login") {
        // console.log("User is logged out. Redirecting to login.");
        history.replace("/login");
      } else if (!isLoggedOut && location.pathname === "/login") {
        const lastValidPath = localStorage.getItem('lastValidPath') || "/dashboard";
        // console.log("User is logged in. Redirecting to last valid path:", lastValidPath);
        history.replace(lastValidPath);
      }
    }
  }, [isLoggedOut, location, history, isValidatingToken]);

  if (isValidatingToken) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  return <Routes />;
}

export default App;


