import {
  GENERATE_TOKEN,
  GET_ALL_NAME_APPROVAL_API,
  LOGOUT,
} from "../actionTypes";
import {
  getListNameApprovals,
  nameApprovelPermission,
} from "../../../api/ApiService";
import store from "../../store/store";
import { validateToken } from "../../../utils";
import { toast } from "react-toastify";

// export const getListNameApprovalsData = (page = 1) => {
//   return async (dispatch) => {
//     console.log("getListNameApprovals called with page:", page);

//     try {
//       const response = await validateToken();
//       dispatch({ type: GENERATE_TOKEN, payload: response });

//       const approvalResponse = await getListNameApprovals({ page });

//       if (approvalResponse && approvalResponse.status === 200) {
//         // dispatch the data to the reducer
//         dispatch({
//           type: GET_ALL_NAME_APPROVAL_API,
//           payload: approvalResponse.data,
//         });

//         console.log(
//           "Dispatched GET_ALL_NAME_APPROVAL_API with payload:",
//           approvalResponse.data
//         );
//       } else {
//         toast.error("Failed to fetch approval data");
//       }
//     } catch (error) {
//       console.error("Error in getListNameApprovalsData:", error);
//       if (approvalResponse && approvalResponse.status === 401)
//       // dispatch({ type: LOGOUT });

//       // window.location.href = "/login";
//       // toast.error("An error occurred while fetching approval data");
//     }
//   };
// };

export const getListNameApprovalsData = (page = 1) => {
  return async (dispatch) => {
    console.log("getListNameApprovals called with page:", page);

    try {
      const response = await validateToken();
      dispatch({ type: GENERATE_TOKEN, payload: response });

      const approvalResponse = await getListNameApprovals({ page });

      if (approvalResponse && approvalResponse.status === 200) {
        // Dispatch the data to the reducer
        dispatch({
          type: GET_ALL_NAME_APPROVAL_API,
          payload: approvalResponse.data,
        });

        console.log(
          "Dispatched GET_ALL_NAME_APPROVAL_API with payload:",
          approvalResponse.data
        );
      }
      //  else {
      //   toast.error("Failed to fetch approval data");
      // }
    } catch (error) {
      console.error("Error in getListNameApprovalsData:", error);

      // Check for error response status codes
      if (error.response) {
        const { status } = error.response;

        if (status == 401 || status == 403) {
          // dispatch(LOGOUT)
          window.location.href = "/login";
          toast.error("Session expired. Please log in again.");
        }
      }
    }
  };
};

export const nameApprovelPermissionData = (data) => {
  return async (dispatch) => {
    try {
      const response = await validateToken();

      dispatch({ type: GENERATE_TOKEN, payload: response });

      const approvalResponse = await nameApprovelPermission(data);

      if (approvalResponse && approvalResponse.status === 200) {
        console.log("Approval/rejection successful", approvalResponse.data);
      } else {
        toast.error("Failed to process the approval request");
      }
    } catch (error) {
      console.error("Error in nameApprovelPermission:", error);

      // toast.error("An error occurred while processing approval/rejection");
    }
  };
};
